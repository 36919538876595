.about {
  position: relative;
}

.about__container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2rem;
  position: relative;
  max-width: 1260px;
  padding: 0 28px;
  width: 100%;
  gap: 4rem;
}

@media screen and (max-width: 900px) {
  .about__container {
    flex-direction: column;
    align-items: center;
    padding: 0 28px;
    gap: 2rem;
  }
}

@media screen and (max-width: 510px) {
  .about__container {
    padding: 0 12px;
  }
}

.about__poster-and-resume {
  flex: 0 1 455px;
  width: 100%;

  flex-direction: column;
}
