.project-screenshot {
  background: #f9f9f9;
  position: relative;
  animation: screenshot-load 1s ease;
  padding: 2rem 1rem 3rem;
}

@media screen and (max-width: 610px) {
  .project-screenshot {
    grid-row: 1;
  }
}

.project-screenshot__inlay {
  display: flex;
  gap: 2rem;
  animation: opacity-load 0.5s;
  width: 100%;
}

@media screen and (max-width: 1065px) {
  .project-screenshot {
    padding-bottom: 4rem;
  }

  .project-screenshot__inlay {
    flex-direction: column;
    gap: 0;
  }
}

@keyframes opacity-load {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.project-screenshot__inlay-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  flex: 1 1 350px;
}

@media screen and (max-width: 1065px) {
  .project-screenshot__inlay-text {
    flex: 1 1 auto;
    max-width: 420px;
  }
}

@media screen and (max-width: 1065px) {
  .project-screenshot__inlay-text {
    padding-left: 1.2rem;
  }
}

.project-screenshot__inlay-text h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.project-screenshot__inlay-text span {
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: justify;
}

.project-screenshot__inlay-pic {
  flex: 2 1 auto;
  padding-top: 6vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.project-screenshot__inlay-pic img {
  width: 100%;
}

@media screen and (max-width: 1065px) {
  .project-screenshot__inlay-pic {
    padding-top: 0;
  }
}

.project-screenshot__arrow-back,
.project-screenshot__arrow-forward {
  width: 50px;
  height: 50px;
  background-color: #ffb82b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.project-screenshot__arrow-back {
  position: absolute;
  bottom: 0;
  right: 100px;
}

.project-screenshot__arrow-forward {
  position: absolute;
  bottom: 0;
  right: 50px;
}

.endpoint {
  background-color: white;
}

@keyframes screenshot-load {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
