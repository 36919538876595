.poster {
  display: flex;
  flex: 0 1 465px;
  z-index: 2;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  section {
    flex: 0 1 auto;
    width: 100%;
  }
}

.about__poster {
  flex: 0 1 455px;
  opacity: 1;
  width: 100%;
  background-color: #ffb82b;
  overflow: hidden;
  position: relative;
  height: 600px;
  min-width: 320px;
  max-width: 455px;
  animation: poster-load 1s ease;
}

.poster__img {
  position: absolute;
  bottom: -10px;
  z-index: 2;
  filter: contrast(1.3);
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 12%;
  animation: words-load 1s ease;
}

.poster__frontend,
.poster__css,
.poster__react,
.poster__html,
.poster__nextjs,
.poster__sass,
.poster__redux,
.poster__graphql,
.poster__git {
  position: absolute;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.2rem;
  animation: words-load 1s ease;
}

.poster__frontend {
  font-weight: 800;
  font-size: 5.2rem;
  top: -2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

@media screen and (max-width: 1230px) {
  .poster__frontend {
    top: 0;
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1075px) {
  .poster__frontend {
    top: 0;
    font-size: 4rem;
  }
}

@media screen and (max-width: 975px) {
  .poster__frontend {
    top: 0;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 900px) {
  .poster__frontend {
    top: 0;
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 499px) {
  .poster__frontend {
    font-size: 4rem;
  }
}

@media screen and (max-width: 399px) {
  .poster__frontend {
    top: 0;
    font-size: 3.5rem;
  }
}

.poster__css {
  /* font-size: 1.8rem; */
  right: 2%;
  bottom: 32%;
}

.poster__react {
  font-size: 3rem;
  right: 5%;
  top: 27%;
}

.poster__html {
  font-size: 2rem;
  left: 1%;
  bottom: 28%;
}

.poster__nextjs {
  text-transform: none;
  /* font-size: 1.9rem; */
  left: 1%;
  top: 36%;
}

.poster__sass {
  font-size: 3rem;
  left: 3%;
  top: 15%;
}

.poster__redux {
  text-transform: none;
  font-size: 2.5rem;
  right: 5%;
  top: 10%;
}

.poster__graphql {
  text-transform: none;
  /* font-size: 1.7rem; */
  right: 1%;
  top: 80%;
}

.poster__git {
  text-transform: none;
  font-size: 2.7rem;
  left: 3%;
  bottom: -3%;
}

@keyframes poster-load {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes words-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
