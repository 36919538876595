@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700;800&display=swap");

html {
  height: 100%;
  font-weight: 500;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
img,
section {
  margin: 0;
  padding: 0;
}
