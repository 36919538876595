.contacts {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  min-height: 90vh;
}

.contacts__container {
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  padding: 40px;
  margin-top: 4vh;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media screen and (max-width: 520px) {
  .contacts__container {
    margin-top: 2vh;
    box-shadow: none;
    background-color: transparent;
    padding: 20px;
  }
}

.contacts__email-location-telefone-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
}

.contacts__data-container {
  display: flex;
}

.contacts__data-icon-container {
  margin-right: 20px;
  font-size: 18px;
}

.contacts__text {
  display: flex;
  color: #000;
  text-decoration: none;
  font-size: 18px;
}

@media screen and (max-width: 390px) {
  .contacts__data-icon-container {
    font-size: 16px;
  }

  .contacts__text {
    font-size: 16px;
  }
}

.contacts__email-container {
  display: flex;
  margin: 0 auto;
  position: relative;
  justify-content: center;
}
