.social-links__description-container {
  margin-bottom: 0.5rem;
}

.social-links__description {
  font-size: 1.2rem;
}

.social-links {
  display: flex;
  align-items: baseline;
}

.social-link__a {
  font-size: 1.4rem;
  color: black;
}

.social-link:not(:last-child) {
  margin-right: 0.8rem;
}

.social-link__a #email {
  font-size: 1.5rem;
}

.social-link__a:hover svg {
  transition: all 0.4s ease;
  color: #ffb82b;
}
