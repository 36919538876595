.email-form {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  animation: form-load 0.7s;
}

@keyframes form-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.email-form__name-label,
.email-form__email-label,
.email-form__message-label {
  margin-bottom: 8px;
}
.email-form__name-input,
.email-form__message-input,
.email-form__email-input {
  margin-bottom: 24px;
  height: 32px;
  border: 3px solid #ffcd6a;
  /* outline-color: #ffaa00; */
  outline: none;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.6s ease;
}

.email-form__name-input:focus,
.email-form__message-input:focus,
.email-form__email-input:focus {
  border: 3px solid #ffb82b;
}

.active {
  border: 3px solid #ffb82b;
}

.email-form__message-input {
  height: 140px;
}

.email-form__submit-button {
  margin: 0 auto;
  border: 0;
  background: #ffcd6a;
  color: black;
  height: 40px;
  width: 110px;
  font-weight: 600;
  transition: all 0.6s ease;
  cursor: pointer;
}

.email-form__submit-button:hover {
  background: #ffb82b;
}
