.project-number {
  animation: resume-load 1s ease;
}

.project-number__span {
  font-size: 10rem;
  font-weight: 700;
  animation: opacity-load 0.5s;
}

@media screen and (max-width: 885px) {
  .project-number__span {
    font-size: 8rem;
  }
}

@keyframes resume-load {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes opacity-load {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
