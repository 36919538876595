.navbar {
  flex: 0 1 80px;
  background-color: white;
  z-index: 3;
  margin-bottom: 2rem;
  position: relative;
}

@media screen and (max-width: 500px) {
  .navbar {
    margin-bottom: 0.5rem;
  }
}

.navbar__container {
  margin: 0 auto;
  display: grid;
  max-width: 1260px;
  padding: 0 28px;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .navbar__container {
    padding: 16px 12px;
  }
}

.navbar__logo-container {
  display: flex;
  align-items: center;
}

.navbar__menu-link-cat {
  color: #ffb82b;
  font-size: 48px;
}

.navbar__menu-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.navbar__social-links {
  display: grid;
  grid-template-columns: 30px 30px;
  gap: 1.5rem;
}

.navbar__social-link__a {
  color: black;
  font-size: 2rem;
  transition: all 0.6s ease;
}

.navbar__social-link__a:hover {
  color: #ffb82b;
}

@media screen and (max-width: 500px) {
  .navbar__social-links {
    gap: 2.5rem;
  }

  .navbar__social-link__a {
    font-size: 2.5rem;
  }
}

.navbar__menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 500px) {
  .navbar__menu {
    position: absolute;
    grid-template-columns: 1fr;
    background: white;
    top: 0;
    padding: 6rem;
    transition: all 0.8s ease;
  }

  .open {
    right: 0;
  }
  .closed {
    right: -100%;
    visibility: hidden;
  }
}

@media screen and (max-width: 575px) {
  .navbar__menu {
    gap: 20px;
  }
}

.navbar__menu-item {
  width: fit-content;
  margin: 22px 0;
}

.navbar__menu-link {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  opacity: 0.5;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .navbar__menu-link {
    font-size: 2rem;
  }
}

.navbar__menu-link-active {
  text-decoration: none;
  position: relative;
  color: black;
  opacity: 1;
  font-size: 1.3rem;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .navbar__menu-link-active {
    font-size: 2rem;
  }
}

.navbar__menu-link-active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ffb82b;
  bottom: -10px;
  left: 0;
}
