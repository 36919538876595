.modal__dark-background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 0;
  animation: modal-load 0.5s;
}

.modal__modal-container {
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 500px;
  width: 94%;
  height: 260px;
  background-color: rgb(255, 255, 255);

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  animation: modal-load 0.5s;
}

@keyframes modal-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal__close-button {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 24px;
  cursor: pointer;
}

.modal__icon {
  margin-left: 10px;
  color: #ffc450;
}

.modal__first-line-container {
  margin-bottom: 1rem;
  font-size: 22px;
  color: black;
  padding: 4px 8px;
}

.modal__second-line-container {
  margin-bottom: 45px;
  font-size: 22px;
  color: black;
  padding: 4px 8px;
}

@media screen and (max-width: 360px) {
  .modal__first-line-container,
  .modal__second-line-container {
    font-size: 18px;
  }
}

.modal__button-container {
  cursor: pointer;
}

.modal__button {
  background: #ffcd6a;
  color: black;
  font-weight: 700;
  padding: 10px 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  transition: all 0.6s ease;
}

.modal__button:hover {
  background: #ffb82b;
}
