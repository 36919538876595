.burger {
  display: none;
}

@media screen and (max-width: 500px) {
  .burger {
    padding: 8px 8px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 3;
  }

  .burger:hover {
    opacity: 0.7;
  }
  .burger.is-active:hover {
    opacity: 0.7;
  }
  .burger.is-active .burger-inner,
  .burger.is-active .burger-inner::before,
  .burger.is-active .burger-inner::after {
    background-color: #000;
  }

  .burger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .burger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .burger-inner,
  .burger-inner::before,
  .burger-inner::after {
    width: 46px;
    height: 6px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .burger-inner::before,
  .burger-inner::after {
    content: "";
    display: block;
  }
  .burger-inner::before {
    top: -14px;
  }
  .burger-inner::after {
    bottom: -14px;
  }

  .burger--vortex .burger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .burger--vortex .burger-inner::before,
  .burger--vortex .burger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear;
  }
  .burger--vortex .burger-inner::before {
    transition-property: top, opacity;
  }
  .burger--vortex .burger-inner::after {
    transition-property: bottom, transform;
  }

  .burger--vortex.is-active .burger-inner {
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .burger--vortex.is-active .burger-inner::before,
  .burger--vortex.is-active .burger-inner::after {
    transition-delay: 0s;
  }
  .burger--vortex.is-active .burger-inner::before {
    top: 0;
    opacity: 0;
  }
  .burger--vortex.is-active .burger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
  }
}
