.portfolio {
  display: flex;
}

.portfolio__container {
  flex: 1 0 auto;
  max-width: 1260px;
  margin: 0 auto 2rem;
  padding: 0 28px;
  min-height: 80vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 2fr 1fr;
}

@media screen and (max-width: 755px) {
  .portfolio__container {
    padding: 0 12px;
  }
}

@media screen and (max-width: 610px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1fr;
    min-height: 500px;
  }
}
