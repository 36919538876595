.about__download-resume {
  max-width: 455px;
  animation: resume-load 1s ease;
}

@keyframes resume-load {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.about__download-button-link {
  display: block;
  font-weight: 600;
  border-radius: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.about__download-button-inside {
  width: 100%;
  height: 50px;
  color: black;
  border: 2px solid black;
  transition: all 400ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2rem;
}

.about__download-button-inside:hover {
  background: black;
  color: white;
}

.about__download-button-inside:active {
  background: #ffb82b;
  border: 2px solid #ffb82b;
}

.about__social-link {
  cursor: pointer;
  width: fit-content;
  font-size: 1.5rem;
}

.about__social-link-a {
  color: black;
  cursor: pointer;
  transition: all 400ms ease;
  display: block;
}

.about__social-link-a:hover {
  color: #ffb82b;
  cursor: pointer;
}
