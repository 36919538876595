.project-description {
  background: black;
  color: white;
  animation: description-load 1s ease;
}

@media screen and (max-width: 610px) {
  .project-description {
    grid-row: 2;
  }
}

@keyframes description-load {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-description__desc {
  max-width: 400px;
  margin-left: 3rem;
  margin-top: 2rem;
}

@media screen and (max-width: 700px) {
  .project-description__desc {
    margin: 2.2rem;
  }
}

.project-description__desc-text {
  animation: opacity-load 0.5s;
}

@keyframes opacity-load {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.project-description__desc h3 {
  display: block;
  width: fit-content;
  font-size: 1.8rem;
  border-left: 0.5rem #ffb82b solid;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.project-description__desc span {
  line-height: 1.5rem;
}

@media screen and (max-width: 700px) {
  .project-description__desc span {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

.project-description__links {
  margin-top: 1rem;
  display: flex;
}

.project-description__link {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  display: block;
  border-bottom: 0.2rem #ffb82b solid;
}

.project-description__link:not(:last-child) {
  margin-right: 2rem;
}
