.project-stack {
  background: #e1e9ef;
  animation: stack-load 1s ease;
  padding: 2rem 1rem 2rem 2rem;
}

@media screen and (max-width: 1065px) {
  .project-stack {
    padding-left: 1rem;
  }
}
@keyframes stack-load {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 610px) {
  .project-stack {
    padding-left: 2rem;
  }
}

h2 {
  display: block;
  border-left: 0.5rem #ffb82b solid;
  width: fit-content;
  font-weight: 800;
  font-size: 2rem;
  padding-left: 0.5rem;
}

.project-stack__list {
  list-style: none;
  padding: 0;
}

.project-stack__list-item {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  animation: opacity-load 0.5s;
}

@keyframes opacity-load {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
