.about__text {
  display: flex;
  justify-content: flex-end;
}

.about__text-container {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.303);
  backdrop-filter: blur(5px);
  background: #f9f9f9;
  padding: 2rem;
  animation: text-load 1s ease;
}

@keyframes text-load {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 900px) {
  .about__text-container {
    margin-top: 0;
    max-width: 455px;
    align-items: flex-end;
  }
}

.about__text-header-container {
  max-width: 400px;
  line-height: 3em;
}

.about__text-header {
  font-size: 42px;
}

@media screen and (max-width: 765px) {
  .about__text-header {
    font-size: 38px;
  }
}

.about__text-header {
  margin-top: 0px;
  margin-bottom: 30px;
}

.about__main-text-container {
  max-width: 600px;
  font-weight: 400;
  text-align: justify;
  font-size: 20px;
}

.about__text_bold {
  font-weight: 600;
}
